import { faFacebook, faTelegram } from '@fortawesome/free-brands-svg-icons';

// Perfect
export const AppId = 1
export const rootUrl = 'https://perfect-app.atwebpages.com/'
export const alternateUrl = 'https://perfect-app.scienceontheweb.net/api/'
export const title = 'Perfect'
export const icon = '/assets/perfect/perfect.ico'
export const screenshot = '/assets/perfect/screenshot.png'
export const noBackgroundLogo = '/assets/perfect/no_background_logo.png';
export const screenshot1 = '/assets/perfect/screenshots/screenshot_1.png';
export const screenshot2 = '/assets/perfect/screenshots/screenshot_2.png';
export const screenshot3 = '/assets/perfect/screenshots/screenshot_3.png';
export const screenshot4 = '/assets/perfect/screenshots/screenshot_4.png';
export const screenshot5 = '/assets/perfect/screenshots/screenshot_5.png';
export const screenshot6 = '/assets/perfect/screenshots/screenshot_6.png';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=edu.perfect';
export const appStoreUrl = '';
export const contactUsLinks = () => [
    {
        icon: faFacebook,
        url: 'https://www.facebook.com/Perfect-101405762102695'
    },
    {
        icon: faTelegram,
        url: 'https://t.me/PerfectSy'
    },
]

//Be Ready
// export const AppId = 2
// export const rootUrl = 'http://be-ready.atwebpages.com/'

//Durous
// export const AppId=3

//DELTA
// export const rootUrl = 'http://delta-app.atwebpages.com/'
// export const AppId = 1

// MRI
// export const AppId = 5
// export const rootUrl = 'https://mri.scienceontheweb.net/'
// export const alternateUrl = 'https://mri-sy.atwebpages.com/api/'

// Masar
// export const AppId = 1
// export const rootUrl = 'https://masar.atwebpages.com/'

// Developer
// export const AppId = 1
// export const rootUrl = 'https://developer-rajab.atwebpages.com/'
// export const alternateUrl = 'https://e-learning-admins.atwebpages.com/api/'
// export const title = 'Perfect'
// export const icon = '/assets/perfect/perfect.ico'
// export const screenshot = '/assets/perfect/screenshot.png'
// export const noBackgroundLogo = '/assets/perfect/no_background_logo.png';
// export const screenshot1 = '/assets/perfect/screenshots/screenshot_1.png';
// export const screenshot2 = '/assets/perfect/screenshots/screenshot_2.png';
// export const screenshot3 = '/assets/perfect/screenshots/screenshot_3.png';
// export const screenshot4 = '/assets/perfect/screenshots/screenshot_4.png';
// export const screenshot5 = '/assets/perfect/screenshots/screenshot_5.png';
// export const screenshot6 = '/assets/perfect/screenshots/screenshot_6.png';
// export const playStoreUrl = 'https://play.google.com/store/apps/details?id=edu.perfect';
// export const appStoreUrl = '';
// export const contactUsLinks = () => [
//     {
//         icon: faFacebook,
//         url: 'https://www.facebook.com/Perfect-101405762102695'
//     },
//     {
//         icon: faTelegram,
//         url: 'https://t.me/PerfectSy'
//     },
// ]

// Admins
// export const AppId = 1
// export const rootUrl = 'https://e-learning-admins.atwebpages.com/'

// Rasoul
// export const AppId = 1
// export const rootUrl = 'https://rasoul.atwebpages.com/'